import { call } from './api.service';
import { createReader, Reader } from './reader.service';

export interface AccountExt {
  id: number;
  createdAt: number;
  lastLoginAt: number;
  isDeleted: boolean;
  username: string;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  gangIds: number[];
  privIds: number[];
}

export interface GangExt {
  id: number;
  createdAt: number;
  isDeleted: boolean;
  gangname: string;
  name: string;
  accountIds: number[];
  privIds: number[];
}

export interface Priv {
  id: number;
  alias: string;
  name: string;
}

export async function getUsersExt(): Promise<AccountExt[]> {
  const result = await call('Main', 'GetUsersExt', []);
  const reader = createReader(result);
  const accounts: AccountExt[] = [];
  while (reader.canRead()) {
    accounts.push({
      id: reader.readNumber(),
      createdAt: reader.readDate(),
      lastLoginAt: reader.readDate(),
      isDeleted: reader.readBoolean(),
      username: reader.readString(),
      firstName: reader.readString(),
      middleName: reader.readString(),
      lastName: reader.readString(),
      name: reader.readString(),
      gangIds: readNumbers(reader),
      privIds: readNumbers(reader),
    });
  }
  for (const a of accounts) {
    (a as any).$ = [a.username, a.firstName, a.middleName, a.lastName].join(' ').toLowerCase();
  }
  return accounts;
}

export async function createUser(data: {
  username: string;
  password: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gangIds: number[];
  privIds: number[];
}): Promise<void> {
  await call('Main', 'CreateUser', [
    data.username,
    data.password,
    data.firstName,
    data.middleName,
    data.lastName,
    data.gangIds.length,
    ...data.gangIds,
    data.privIds.length,
    ...data.privIds,
  ]);
}

export async function updateUser(
  accountId: number,
  data: {
    username: string;
    password: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gangIds: number[];
    privIds: number[];
  },
): Promise<void> {
  await call('Main', 'UpdateUser', [
    accountId,
    data.username,
    data.password,
    data.firstName,
    data.middleName,
    data.lastName,
    data.gangIds.length,
    ...data.gangIds,
    data.privIds.length,
    ...data.privIds,
  ]);
}

export async function deleteUser(accountId: number): Promise<void> {
  await call('Main', 'DeleteUser', [accountId]);
}

export async function restoreUser(accountId: number): Promise<void> {
  await call('Main', 'RestoreUser', [accountId]);
}

export async function getGroupsExt(): Promise<GangExt[]> {
  const result = await call('Main', 'GetGroupsExt', []);
  const reader = createReader(result);
  const gangs: GangExt[] = [];
  while (reader.canRead()) {
    gangs.push({
      id: reader.readNumber(),
      createdAt: reader.readDate(),
      isDeleted: reader.readBoolean(),
      gangname: reader.readString(),
      name: reader.readString(),
      accountIds: readNumbers(reader),
      privIds: readNumbers(reader),
    });
  }
  for (const g of gangs) {
    (g as any).$ = [g.gangname, g.name].join(' ').toLowerCase();
  }
  return gangs;
}

export async function createGroup(data: { gangname: string; name: string; privIds: number[] }): Promise<void> {
  await call('Main', 'CreateGroup', [data.gangname, data.name, data.privIds.length, ...data.privIds]);
}

export async function updateGroup(
  gangId: number,
  data: { gangname: string; name: string; privIds: number[] },
): Promise<void> {
  await call('Main', 'UpdateGroup', [gangId, data.gangname, data.name, data.privIds.length, ...data.privIds]);
}

export async function updateGroupMembers(gangId: number, data: { accountIds: number[] }): Promise<void> {
  await call('Main', 'UpdateGroupMembers', [gangId, ...data.accountIds]);
}

export async function deleteGroup(gangId: number): Promise<void> {
  await call('Main', 'DeleteGroup', [gangId]);
}

export async function restoreGroup(gangId: number): Promise<void> {
  await call('Main', 'RestoreGroup', [gangId]);
}

export async function getPrivs(): Promise<Priv[]> {
  const result = await call('Main', 'GetPrivs', []);
  const reader = createReader(result);
  const privs: Priv[] = [];
  while (reader.canRead()) {
    privs.push({
      id: reader.readNumber(),
      alias: reader.readString(),
      name: reader.readString(),
    });
  }
  return privs;
}

export function isNullDate(value: number): boolean {
  return value <= 0;
}

// private

function readNumbers(reader: Reader): number[] {
  const count = reader.readNumber();
  const numbers: number[] = [];
  for (let i = 0; i < count; i += 1) {
    numbers.push(reader.readNumber());
  }
  return numbers;
}
