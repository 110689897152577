import * as dateFns from 'date-fns';

import { AccountExt } from './sdk.service';

export function formatDate(date: string | number | null | undefined) {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'dd.MM.yyyy');
}

export function formatAccountFullName(
  account: AccountExt,
  { withUsernameFallback }: { withUsernameFallback?: boolean } = {},
) {
  const fullName = [account.lastName, account.firstName, account.middleName].filter(Boolean).join(' ');
  if (fullName) {
    return fullName;
  }
  return withUsernameFallback ? account.username : '';
}
