export interface User {
  username: string;
}

export function getUser(): User | undefined {
  return user;
}

export function setUser(u: User | undefined): void {
  user = u;
}

// private

let user: User | undefined;
