import React from 'react';

import { login, logout } from '../../services/api.service';
import { getUser, setUser } from '../../services/auth.service';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { getLsItem, LSK_USERNAME, setLsItem } from '../../services/ls.service';
import { goTo } from '../../services/router.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';

import './PLogin.css';

interface S {
  isLoading: boolean;
  form: Form;
}

export class PLogin extends React.Component<unknown, S> {
  constructor(props: unknown) {
    const username = getUser() ? undefined : getLsItem<string>(LSK_USERNAME);
    super(props);
    this.state = {
      form: createForm({ username }),
      isLoading: false,
    };
  }

  render() {
    const { form, isLoading } = this.state;
    return (
      <div className="PLogin align-items-center d-flex h-100 justify-content-center">
        <div className="card bg-light w-25">
          <h4 className="card-header">Пользователи и группы</h4>
          <form className="card-body">
            <FormGroup
              type="text"
              name="username"
              placeholder="Логин"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="password"
              name="password"
              placeholder="Пароль"
              form={form}
              onChange={(x) => this.onFormChange(x)}
              onSubmit={() => this.onLogin()}
            />
            <Button
              className="w-100 mb-2"
              type="primary"
              text="Вход"
              disabled={!this.isFilled(form) || isLoading}
              onClick={() => this.onLogin()}
            />
          </form>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (getUser()) {
      setLsItem(LSK_USERNAME, undefined);
      setUser(undefined);
      logout();
    }
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form: form.resetErrors() });
  }

  async onLogin() {
    const { form } = this.state;
    if (!this.isFilled(form)) {
      return;
    }
    const username = form.getValue('username');
    const password = form.getValue('password');
    this.setState({ isLoading: true });
    try {
      const success = await login(username, password);
      if (!success) {
        alert('Неверный логин или пароль');
        this.setState({ isLoading: false });
        return;
      }
      setLsItem(LSK_USERNAME, username);
      setUser({ username });
      goTo('/users');
    } catch (e) {
      handleError(e);
    }
  }

  // other helpers

  isFilled(form: Form) {
    return Boolean(form.getValue('username')) && Boolean(form.getValue('password'));
  }
}
