import React from 'react';

import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { AccountExt, GangExt, updateGroupMembers } from '../../services/sdk.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

interface P {
  close: () => void;
  gang: GangExt;
  accounts: AccountExt[];
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MMembers extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.gang),
    };
  }

  render() {
    return (
      <div className="MMembers modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { close, gang } = this.props;
    const { form } = this.state;
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      await updateGroupMembers(gang.id, pd);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, gang } = this.props;
    return <ModalHeader title={gang.name} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const count = this.getIds(form, 'members').length;
    return (
      <div className="modal-body">
        <FormGroup
          type="checks"
          name="members"
          label={`Участники (${count})`}
          form={form}
          options={this.getMemberOptions()}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(gang: GangExt) {
    return createForm({
      members: this.getMembersFormValue(gang),
    });
  }

  getMembersFormValue(gang: GangExt) {
    return gang.accountIds.join('\n');
  }

  getMemberOptions(): Option[] {
    const { accounts } = this.props;
    return accounts.filter((a) => !a.isDeleted).map((a) => ({ value: String(a.id), title: a.name }));
  }

  getPostData(form: Form) {
    return {
      accountIds: this.getIds(form, 'members'),
    };
  }

  getIds(form: Form, name: string) {
    const value = form.getValue(name);
    if (!value) {
      return [];
    }
    return value.split('\n').map(Number);
  }
}
