export interface Reader {
  canRead(): boolean;
  readString(): string;
  readNumber(): number;
  readBoolean(): boolean;
  readDate(): number;
}

export function createReader(input: string[]): Reader {
  let pos = 0;
  const reader: Reader = {
    canRead() {
      return pos < input.length;
    },
    readString() {
      pos++;
      return input[pos - 1];
    },
    readNumber() {
      return Number(reader.readString());
    },
    readBoolean() {
      return reader.readString() !== '0';
    },
    readDate() {
      return reader.readNumber();
    },
  };
  return reader;
}
