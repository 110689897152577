import React from 'react';

import { handleError } from '../../services/error.service';
import { formatDate } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import {
  AccountExt,
  GangExt,
  Priv,
  deleteGroup,
  getGroupsExt,
  getPrivs,
  getUsersExt,
  restoreGroup,
} from '../../services/sdk.service';
import { Button } from '../Button';
import { Column, List, MenuItem } from '../List';
import { Option, Select } from '../Select';
import { MGroup } from '../modals/MGroup';
import { MMembers } from '../modals/MMembers';

interface S {
  gangs: GangExt[];
  accounts: AccountExt[];
  privs: Priv[];
  search: string;
  status: 'active' | 'deleted';
}

export class PGroups extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      gangs: [],
      accounts: [],
      privs: [],
      search: '',
      status: 'active',
    };
  }

  render() {
    const { search, status } = this.state;
    return (
      <div className="PGroups pb-3">
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
          <Select
            options={this.getStatusOptions()}
            className="ml-3"
            value={status}
            onChange={(value) => this.onStatusChange(value)}
          />
        </div>
        <List
          items={this.getItems()}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const { privs } = this.state;
    await showModal(MGroup, { privs, isEditMode: true });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  async onStatusChange(value: string) {
    this.setState({ status: value as 'active' | 'deleted' });
  }

  onGetItemMenu(gang: GangExt): MenuItem[] {
    return [
      {
        name: 'Открыть',
        action: async () => {
          await this.runPrimaryAction(gang);
        },
      },
      {
        isHidden: gang.isDeleted,
        name: 'Редактировать группу',
        action: async () => {
          const { privs } = this.state;
          await showModal(MGroup, { privs, gang, isEditMode: true });
          await this.loadData();
        },
      },
      {
        isHidden: gang.isDeleted,
        name: 'Редактировать участников',
        action: async () => {
          const { accounts } = this.state;
          await showModal(MMembers, { gang, accounts });
          await this.loadData();
        },
      },
      {
        isHidden: gang.isDeleted,
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить группу "${gang.gangname}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await deleteGroup(gang.id);
          } catch (err) {
            handleError(err);
            return;
          }
          await this.loadData();
        },
      },
      {
        isHidden: !gang.isDeleted,
        name: 'Восстановить',
        action: async () => {
          try {
            await restoreGroup(gang.id);
          } catch (err) {
            handleError(err);
            return;
          }
          await this.loadData();
        },
      },
    ];
  }

  async onItemSelect(gang: GangExt) {
    await this.runPrimaryAction(gang);
  }

  // other helpers

  getStatusOptions(): Option[] {
    return [
      { value: 'active', title: 'Активные' },
      { value: 'deleted', title: 'Удалённые' },
    ];
  }

  getItems(): GangExt[] {
    const { gangs, status } = this.state;
    const isDeleted = status === 'deleted';
    return gangs.filter((g) => g.isDeleted === isDeleted);
  }

  getColumns(): Column<GangExt>[] {
    return [
      {
        name: 'Название',
        value: (item) => item.name,
      },
      {
        name: 'Код',
        value: (item) => item.gangname,
        headClassName: 'w-200px',
      },
      {
        name: 'Участники',
        value: (item) => item.accountIds.length,
        headClassName: 'w-120px text-center',
        cellClassName: () => 'text-center',
      },
      {
        name: 'Создана',
        value: (item) => formatDate(item.createdAt),
        headClassName: 'w-120px text-center',
        cellClassName: () => 'text-center',
      },
    ];
  }

  async loadData() {
    try {
      const [gangs, accounts, privs] = await Promise.all([getGroupsExt(), getUsersExt(), getPrivs()]);
      this.setState({ gangs, accounts, privs });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(gang: GangExt) {
    const { privs } = this.state;
    await showModal(MGroup, { privs, gang });
  }
}
