import React from 'react';

import { config } from '../../config';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { createGroup, GangExt, Priv, updateGroup } from '../../services/sdk.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

interface P {
  close: () => void;
  privs: Priv[];
  gang?: GangExt;
  isEditMode?: boolean;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MGroup extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.gang),
    };
  }

  render() {
    return (
      <div className="MGroup modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    form = form.setValue('gangname', form.getValue('gangname').toLowerCase());
    this.setState({ form });
  }

  async onSave() {
    const { close, gang } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('gangname');
    form = form.validateRequired('name');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      await (gang ? updateGroup(gang.id, pd) : createGroup(pd));
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, gang } = this.props;
    const title = gang ? gang.name : 'Новая группа';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { isEditMode } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <div className="col-4">
            <FormGroup
              type="text"
              name="gangname"
              label="Код"
              disabled={!isEditMode}
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="name"
              label="Название"
              disabled={!isEditMode}
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
          </div>
          <FormGroup
            className="col-8"
            type="checks"
            name="privs"
            label="Привилегии"
            disabled={!isEditMode}
            form={form}
            options={this.getPrivsOptions()}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close, isEditMode } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button
          type="secondary"
          text={isEditMode ? 'Отмена' : 'Закрыть'}
          disabled={isLoading}
          onClick={() => close()}
        />
        {isEditMode && <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />}
      </div>
    );
  }

  // other helpers

  initForm(gang?: GangExt) {
    if (!gang) {
      return createForm();
    }
    return createForm({
      gangname: gang.gangname,
      name: gang.name,
      privs: this.getPrivsFormValue(gang),
    });
  }

  getPrivsFormValue(gang: GangExt) {
    return gang.privIds.join('\n');
  }

  getPrivsOptions(): Option[] {
    const { privs } = this.props;
    return privs.map((p) => ({ value: String(p.id), title: p.name }));
  }

  getPostData(form: Form) {
    return {
      gangname: form.getValue('gangname'),
      name: form.getValue('name'),
      privIds: this.getIds(form, 'privs'),
    };
  }

  getIds(form: Form, name: string) {
    const value = form.getValue(name);
    if (!value) {
      return [];
    }
    return value.split('\n').map(Number);
  }
}
