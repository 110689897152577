import React from 'react';
import ReactDOM from 'react-dom';

import 'awesomplete';
import 'awesomplete/awesomplete.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons from '@fortawesome/free-solid-svg-icons';

import { App } from './components/App';

import './style.css';

library.add(icons.faBars);
library.add(icons.faArrowDown);
library.add(icons.faArrowUp);
library.add(icons.faPlus);
library.add(icons.faTrash);
library.add(icons.faTimesCircle);
library.add(icons.faEllipsisH);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
