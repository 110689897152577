import React from 'react';

import { getUser } from '../../services/auth.service';
import { handleError } from '../../services/error.service';
import { formatAccountFullName, formatDate } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import {
  AccountExt,
  GangExt,
  Priv,
  deleteUser,
  getGroupsExt,
  getPrivs,
  getUsersExt,
  isNullDate,
  restoreUser,
} from '../../services/sdk.service';
import { Button } from '../Button';
import { Column, List, MenuItem } from '../List';
import { Option, Select } from '../Select';
import { MUser } from '../modals/MUser';

interface S {
  accounts: AccountExt[];
  gangs: GangExt[];
  privs: Priv[];
  search: string;
  filter: string;
}

const filterActive = 'active';
const filterDeleted = 'deleted';

export class PUsers extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      accounts: [],
      gangs: [],
      privs: [],
      search: '',
      filter: filterActive,
    };
  }

  render() {
    const { search, filter } = this.state;
    return (
      <div className="PUsers pb-3">
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
          <Select
            options={this.getFilterOptions()}
            className="ml-3"
            value={filter}
            onChange={(value) => this.onFilterChange(value)}
          />
        </div>
        <List
          items={this.getItems()}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const { gangs, privs } = this.state;
    await showModal(MUser, { gangs, privs, isEditMode: true });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  async onFilterChange(value: string) {
    this.setState({ filter: value });
  }

  onGetItemMenu(account: AccountExt): MenuItem[] {
    return [
      {
        name: 'Открыть',
        action: async () => {
          await this.runPrimaryAction(account);
        },
      },
      {
        isHidden: account.isDeleted,
        name: 'Редактировать',
        action: async () => {
          const { gangs, privs } = this.state;
          await showModal(MUser, { gangs, privs, account, isEditMode: true });
          await this.loadData();
        },
      },
      {
        isHidden: account.isDeleted || account.username === getUser()?.username,
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить пользователя "${account.username}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await deleteUser(account.id);
          } catch (err) {
            handleError(err);
            return;
          }
          await this.loadData();
        },
      },
      {
        isHidden: !account.isDeleted,
        name: 'Восстановить',
        action: async () => {
          try {
            await restoreUser(account.id);
          } catch (err) {
            handleError(err);
            return;
          }
          await this.loadData();
        },
      },
    ];
  }

  async onItemSelect(account: AccountExt) {
    await this.runPrimaryAction(account);
  }

  // other helpers

  getFilterOptions(): Option[] {
    const gangs = this.state.gangs.filter((g) => g.accountIds.length > 0);
    const options: Option[] = [
      { value: filterActive, title: 'Активные' },
      { value: filterDeleted, title: 'Удалённые' },
    ];
    if (gangs.length > 0) {
      options.push({ value: '', title: '–', disabled: true });
      options.push(...gangs.map((g) => ({ value: String(g.id), title: `${g.name} (${g.accountIds.length})` })));
    }
    return options;
  }

  getItems(): AccountExt[] {
    const { accounts, filter } = this.state;
    return accounts.filter((a) => {
      if (filter === filterActive) {
        return !a.isDeleted;
      }
      if (filter === filterDeleted) {
        return a.isDeleted;
      }
      return a.gangIds.includes(Number(filter));
    });
  }

  getColumns(): Column<AccountExt>[] {
    return [
      {
        name: 'ФИО',
        value: formatAccountFullName,
      },
      {
        name: 'Логин',
        value: (item) => item.username,
        headClassName: 'w-200px',
      },
      {
        name: 'Создан',
        value: (item) => formatDate(item.createdAt),
        headClassName: 'w-120px text-center',
        cellClassName: () => 'text-center',
      },
      {
        name: 'Активен',
        value: (item) => (isNullDate(item.lastLoginAt) ? null : formatDate(item.lastLoginAt)),
        headClassName: 'w-120px text-center',
        cellClassName: () => 'text-center',
      },
    ];
  }

  async loadData() {
    try {
      const [accounts, gangs, privs] = await Promise.all([getUsersExt(), getGroupsExt(), getPrivs()]);
      this.setState({ accounts, gangs, privs });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(account: AccountExt) {
    const { gangs, privs } = this.state;
    await showModal(MUser, { gangs, privs, account });
  }
}
