import { config } from '../config';
import { replace } from '../lib/utils.lib';

export async function login(username: string, password: string): Promise<boolean> {
  const response = await fetch(`${config.api}/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });
  const json = await response.json();
  if (!json) {
    throw new Error('BAD_RESPONSE');
  }
  if (!json.sid) {
    return false;
  }
  sid = json.sid;
  return true;
}

export function logout(): void {
  sid = '';
}

export async function call(module: string, func: string, prms: (string | number)[]): Promise<string[]> {
  const response = await fetch(`${config.api}/call`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ sid, module, func, prms: packParams(prms) }),
  });
  const json = await response.json();
  if (!json) {
    throw new Error('BAD_RESPONSE');
  }
  if (!json.result) {
    throw new Error('BAD_RESPONSE');
  }
  const result = unpackResult(json.result);
  switch (result[0]) {
    case 'OK':
      break;
    case 'ERROR':
      throw new Error(result[1]);
    case 'NOSESSION':
      throw new Error('NOSESSION');
    default:
      throw new Error('WEIRD');
  }
  result.shift();
  return result;
}

// private

let sid = '';

function packParams(arr: (string | number)[]): string {
  const tmp = [];
  for (let i = 0; i < arr.length; i++) {
    tmp.push(replace(arr[i] + '', '|', '/'));
  }
  const str = tmp.join('|');
  return str;
}

function unpackResult(str: string): string[] {
  if (!str) {
    return [];
  }
  return str.split('|');
}
