import React from 'react';

import { config } from '../../config';
import { handleError } from '../../services/error.service';
import { formatAccountFullName } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { AccountExt, createUser, GangExt, Priv, updateUser } from '../../services/sdk.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

interface P {
  close: () => void;
  gangs: GangExt[];
  privs: Priv[];
  account?: AccountExt;
  isEditMode?: boolean;
}

interface S {
  form: Form;
  privIds: number[];
  isLoading: boolean;
}

export class MUser extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      privIds: props.account ? props.account.privIds : [],
      form: this.initForm(props.account),
    };
  }

  render() {
    return (
      <div className="MUser modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    const { privIds } = this.state;
    const gangIds = this.getIds(form, 'gangs');
    form = form.setValue('privs', this.getPrivsFormValue(gangIds, privIds));
    form = form.setValue('username', form.getValue('username').toLowerCase());
    this.setState({ form });
  }

  onPrivsChange(form: Form) {
    const privIds = this.getIds(form, 'privs').filter((privId) => !this.isAvailableThroughGang(form, privId));
    this.setState({ form, privIds });
  }

  async onSave() {
    const { close, account } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('username');
    if (form.getValue('password1') && form.getValue('password1').length < 6) {
      form = form.setError('password1', 'Не менее 6 символов');
    }
    if (form.getValue('password1') && form.getValue('password1') !== form.getValue('password2')) {
      form = form.setError('password2', 'Пароли не совпадают');
    }
    if (!account) {
      form = form.validateRequired('password1');
      form = form.validateRequired('password2');
    }
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      await (account ? updateUser(account.id, pd) : createUser(pd));
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, account } = this.props;
    const title = account ? formatAccountFullName(account, { withUsernameFallback: true }) : 'Новый пользователь';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { account, isEditMode } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="username"
            label="Логин"
            disabled={!isEditMode}
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="password"
            name="password1"
            label={account ? 'Новый пароль' : 'Пароль'}
            disabled={!isEditMode}
            required={!account}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="password"
            name="password2"
            label={account ? 'Новый пароль ещё раз' : 'Пароль ещё раз'}
            disabled={!isEditMode}
            required={!account}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="lastName"
            label="Фамилия"
            disabled={!isEditMode}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="text"
            name="firstName"
            label="Имя"
            disabled={!isEditMode}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="text"
            name="middleName"
            label="Отчество"
            disabled={!isEditMode}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-4"
            type="checks"
            name="gangs"
            label="Группы"
            disabled={!isEditMode}
            form={form}
            options={this.getGangsOptions()}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col-8"
            type="checks"
            name="privs"
            label="Привилегии"
            disabled={!isEditMode}
            form={form}
            options={this.getPrivsOptions()}
            onChange={(x) => this.onPrivsChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close, isEditMode } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button
          type="secondary"
          text={isEditMode ? 'Отмена' : 'Закрыть'}
          disabled={isLoading}
          onClick={() => close()}
        />
        {isEditMode && <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />}
      </div>
    );
  }

  // other helpers

  initForm(account?: AccountExt) {
    if (!account) {
      return createForm();
    }
    return createForm({
      username: account.username,
      firstName: account.firstName,
      middleName: account.middleName,
      lastName: account.lastName,
      gangs: this.getGangsFormValue(account),
      privs: this.getPrivsFormValue(account.gangIds, account.privIds),
    });
  }

  getGangsFormValue(account: AccountExt) {
    return account.gangIds.join('\n');
  }

  getPrivsFormValue(gangIds: number[], privIds: number[]) {
    const { gangs } = this.props;
    const ids = [...privIds];
    for (const g of gangs) {
      if (gangIds.includes(g.id)) {
        ids.push(...g.privIds);
      }
    }
    const set = new Set(ids);
    return Array.from(set.values()).join('\n');
  }

  getGangsOptions(): Option[] {
    const { gangs } = this.props;
    return gangs.filter((g) => !g.isDeleted).map((g) => ({ value: String(g.id), title: g.name }));
  }

  getPrivsOptions(): Option[] {
    const { privs } = this.props;
    const { form } = this.state;
    return privs.map((p) => ({
      value: String(p.id),
      title: p.name,
      disabled: this.isAvailableThroughGang(form, p.id),
    }));
  }

  getPostData(form: Form) {
    return {
      username: form.getValue('username'),
      password: form.getValue('password1'),
      firstName: form.getValue('firstName'),
      middleName: form.getValue('middleName'),
      lastName: form.getValue('lastName'),
      gangIds: this.getIds(form, 'gangs'),
      privIds: this.getIds(form, 'privs').filter((privId) => !this.isAvailableThroughGang(form, privId)),
    };
  }

  isAvailableThroughGang(form: Form, privId: number) {
    const { gangs } = this.props;
    const gangIds = this.getIds(form, 'gangs');
    for (const g of gangs) {
      if (gangIds.includes(g.id) && g.privIds.includes(privId)) {
        return true;
      }
    }
    return false;
  }

  getIds(form: Form, name: string) {
    const value = form.getValue(name);
    if (!value) {
      return [];
    }
    return value.split('\n').map(Number);
  }
}
